import { Trade } from '@uniswap/router-sdk'
import { Currency, CurrencyAmount, Percent, TradeType } from '@uniswap/sdk-core'

import {
  ALLOWED_PRICE_IMPACT_HIGH,
  ALLOWED_PRICE_IMPACT_LOW,
  ALLOWED_PRICE_IMPACT_MEDIUM,
  BLOCKED_PRICE_IMPACT_NON_EXPERT,
  ZERO_PERCENT,
} from '../constants/misc'

// const THIRTY_BIPS_FEE = new Percent(JSBI.BigInt(30), JSBI.BigInt(10000))
// const INPUT_FRACTION_AFTER_FEE = ONE_HUNDRED_PERCENT.subtract(THIRTY_BIPS_FEE)

export function computeRealizedPriceImpact(trade: Trade<Currency, Currency, TradeType>): Percent {
  // const realizedLpFeePercent = computeRealizedLPFeePercent(trade)
  // return trade.priceImpact.subtract(realizedLpFeePercent)
  return ZERO_PERCENT
}

export function getPriceImpactWarning(priceImpact?: Percent): 'warning' | 'error' | undefined {
  if (priceImpact?.greaterThan(ALLOWED_PRICE_IMPACT_HIGH)) return 'error'
  if (priceImpact?.greaterThan(ALLOWED_PRICE_IMPACT_MEDIUM)) return 'warning'
  return
}

// computes realized lp fee as a percent
export function computeRealizedLPFeePercent(trade: Trade<Currency, Currency, TradeType>): Percent {
  // let percent: Percent

  // // Since routes are either all v2 or all v3 right now, calculate separately
  // if (trade.swaps[0].route.pools instanceof Pair) {
  //   // for each hop in our trade, take away the x*y=k price impact from 0.3% fees
  //   // e.g. for 3 tokens/2 hops: 1 - ((1 - .03) * (1-.03))
  //   percent = ONE_HUNDRED_PERCENT.subtract(
  //     trade.swaps.reduce<Percent>(
  //       (currentFee: Percent): Percent => currentFee.multiply(INPUT_FRACTION_AFTER_FEE),
  //       ONE_HUNDRED_PERCENT
  //     )
  //   )
  // } else {
  //   percent = ZERO_PERCENT
  //   for (const swap of trade.swaps) {
  //     const { numerator, denominator } = swap.inputAmount.divide(trade.inputAmount)
  //     const overallPercent = new Percent(numerator, denominator)

  //     const routeRealizedLPFeePercent = overallPercent.multiply(
  //       ONE_HUNDRED_PERCENT.subtract(
  //         swap.route.pools.reduce<Percent>((currentFee: Percent, pool): Percent => {
  //           const fee =
  //             pool instanceof Pair
  //               ? // not currently possible given protocol check above, but not fatal
  //                 FeeAmount.MEDIUM
  //               : pool.fee
  //           return currentFee.multiply(ONE_HUNDRED_PERCENT.subtract(new Fraction(fee, 1_000_000)))
  //         }, ONE_HUNDRED_PERCENT)
  //       )
  //     )

  //     percent = percent.add(routeRealizedLPFeePercent)
  //   }
  // }

  // return new Percent(percent.numerator, percent.denominator)
  return ZERO_PERCENT
}

// computes price breakdown for the trade
export function computeRealizedLPFeeAmount(
  trade?: Trade<Currency, Currency, TradeType> | null
): CurrencyAmount<Currency> | undefined {
  if (trade) {
    const realizedLPFee = computeRealizedLPFeePercent(trade)

    // the amount of the input that accrues to LPs
    return CurrencyAmount.fromRawAmount(trade.inputAmount.currency, trade.inputAmount.multiply(realizedLPFee).quotient)
  }

  return undefined
}

const IMPACT_TIERS = [
  BLOCKED_PRICE_IMPACT_NON_EXPERT,
  ALLOWED_PRICE_IMPACT_HIGH,
  ALLOWED_PRICE_IMPACT_MEDIUM,
  ALLOWED_PRICE_IMPACT_LOW,
]

type WarningSeverity = 0 | 1 | 2 | 3 | 4
export function warningSeverity(priceImpact: Percent | undefined): WarningSeverity {
  if (!priceImpact) return 4
  let impact: WarningSeverity = IMPACT_TIERS.length as WarningSeverity
  for (const impactLevel of IMPACT_TIERS) {
    if (impactLevel.lessThan(priceImpact)) return impact
    impact--
  }
  return 0
}
